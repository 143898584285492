import { FC } from 'react';
import classnames from 'classnames';
import { Controller } from 'react-hook-form';
import Select, { components } from 'react-select';
import Form from '../index';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n';
import { useSuspenseQuery } from '@tanstack/react-query';
import rqOptions from '../../../rq/options';

export const DEFAULT_PHONE_PREFIX = () => {
  if (i18n?.languages?.includes('pt')) {
    return '+55';
  }
  return '+34';
};

export const Fields = {
  prefix: {
    name: 'prefix',
  },
  phone: {
    name: 'phone',
  },
};

export interface Props {
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  register: any;
  control: any;
  isDirty?: any;
  errors: any;
  menuPlacement?: 'top' | 'bottom';
  onChange?: (value: string) => void;
}

const Phone: FC<Props> = ({
  required,
  disabled,
  readOnly,
  register,
  control,
  isDirty,
  errors,
  onChange,
  menuPlacement = 'bottom',
}) => {
  const { t, i18n } = useTranslation();
  const language = i18n.resolvedLanguage ?? 'es';
  const { data: countries } = useSuspenseQuery(
    rqOptions.countries.getAll(language),
  );
  const options = countries.map(({ prefix, name }) => ({
    value: prefix,
    label: `${name} (${prefix})`,
  }));

  return (
    <div
      className={classnames('flex gap-2 h-[55px]', { 'opacity-50': disabled })}
    >
      <Dropdown
        disabled={disabled}
        readOnly={readOnly}
        errors={errors}
        control={control}
        menuPlacement={menuPlacement}
        defaultValue={DEFAULT_PHONE_PREFIX}
        name={Fields.prefix.name}
        options={options}
      />
      <Form.Field
        required={required}
        disabled={disabled}
        readOnly={readOnly}
        key={Fields.phone.name}
        label={t('form_field_phone_label')}
        type="tel"
        placeholder={t('form_field_phone_label')}
        name={Fields.phone.name}
        register={register}
        isDirty={isDirty}
        onChange={onChange}
        errors={errors}
        className="rounded-lg"
      />
    </div>
  );
};

const Dropdown: FC<any> = ({
  menuPlacement,
  name,
  required,
  disabled,
  options,
  control,
  defaultValue,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
      }}
      render={({ field: { onChange, value, ref } }) => (
        <Select
          isDisabled={disabled}
          className={classnames('Dropdown prefix w-28')}
          styles={{
            menu: (provided) => ({ ...provided, width: '250px' }),
            singleValue: (base) => ({ ...base }),
          }}
          components={{ SingleValue }}
          menuPlacement={menuPlacement}
          isSearchable={true}
          classNamePrefix="ReactDropdown"
          options={options}
          ref={ref}
          value={options.find((o: any) => o.value === value)}
          defaultValue={options.find((o: any) => o.value === defaultValue)}
          onChange={(selectedOption: any) => onChange(selectedOption.value)}
        />
      )}
    />
  );
};

const SingleValue: FC<any> = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    {children.match(/\((.*)\)/)[1]}
  </components.SingleValue>
);

export default Phone;
