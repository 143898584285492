import classNames from 'classnames';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Home from '../assets/icons/Home';
import DoorArrowRight from '../assets/icons/DoorArrowRight';
import ThreeLines from '../assets/icons/ThreeLines';
import Profile from '../assets/icons/Profile';
import Logo from '../assets/images/Logo';
import Modal from './Modal';
import { usePortal } from './Portal';
import FeatureFlag from './FeatureFlag';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import CalendarIcon from '../assets/icons/CalendarIcon';
import ArrowLeftIcon from '../assets/icons/ArrowLeftIcon';

const ROUTES_WITHOUT_BACK_BUTTON = ['/home', '/'];

const LogoContainer = ({
  setScreen,
  isProfileComplete,
}: {
  setScreen?: (screen: string) => void;
  screen?: string;
  hideButton?: boolean;
  isProfileComplete?: boolean;
}) => {
  const isLogged = localStorage.getItem('jwtToken') !== null;
  const [isOpen, setIsOpen] = useState(false);
  const portal = usePortal();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const shouldShowBackButton =
    !ROUTES_WITHOUT_BACK_BUTTON.includes(pathname) &&
    isLogged &&
    isProfileComplete;

  const openMenu = () => {
    if (isOpen) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
      portal.open(
        <NavbarModal
          onClose={() => {
            setIsOpen(false);
            portal.close('modal_menu');
          }}
          setScreen={setScreen}
        />,
        'modal_menu',
      );
    }
  };

  return (
    <div>
      <div className="relative flex justify-center items-center w-full py-4 px-6 xl:ml-0 z-40 bg-white border-b border-b-separators">
        {/* Left side */}
        {shouldShowBackButton ? (
          <Link className="absolute left-3 w-6 h-6 text-secondary" to="/home">
            <ArrowLeftIcon />
          </Link>
        ) : null}

        {/* Center */}
        <button
          type="button"
          onClick={() => isLogged && isProfileComplete && navigate('/home')}
        >
          <Logo />
        </button>

        {/* Right side */}
        {isLogged && isProfileComplete && (
          <button
            type="button"
            onClick={openMenu}
            className={classNames(
              'absolute right-3 border-lg border-gray-light rounded-sm',
              {
                'bg-primary text-white': isOpen,
                'text-secondary': !isOpen,
              },
            )}
          >
            <ThreeLines className="items-center w-6 h-6" />
          </button>
        )}
      </div>
    </div>
  );
};

const NavbarModal = ({
  onClose,
  setScreen,
}: {
  onClose?: () => void;
  setScreen?: (screen: string) => void;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onLogout = () => {
    onClose?.();
    localStorage.clear();
    window.location.href = '/';
  };

  const onProfile = () => {
    onClose?.();
    setScreen?.('profile');
    navigate('/home');
  };

  const onVerify = () => {
    onClose?.();
    navigate('/profile');
  };

  const onAgenda = () => {
    onClose?.();
    navigate('/agenda');
  };

  return (
    <Modal
      backdropDark
      className="rounded-b-2xl max-w-full w-full mt-3 translate-y-10 md:w-[178px] md:right-6 md:absolute md:rounded-2xl md:translate-y-8 md:bg-background "
      width="custom"
      rounded="none"
      padding={false}
      position="top"
      onClose={onClose}
      openDown
    >
      <div className="rounded-b-xl flex flex-col shadow-lg w-full  gap-2 p-2.5">
        <FeatureFlag enabled={true}>
          <button
            className="flex flex-row w-full items-center rounded-lg justify-start p-1 gap-2 hover:bg-gray-light text-xs"
            onClick={onProfile}
          >
            <Home />
            {t('menu__option_home')}
          </button>
        </FeatureFlag>
        <FeatureFlag enabled={true}>
          <button
            className="flex flex-row w-full items-center rounded-lg justify-start p-1 gap-2 hover:bg-gray-light text-xs"
            onClick={onVerify}
          >
            <Profile />
            {t('menu__option_profile')}
          </button>
        </FeatureFlag>
        <FeatureFlag enabled={true}>
          <button
            className="flex flex-row w-full items-center rounded-lg justify-start p-1 gap-2 hover:bg-gray-light text-xs"
            onClick={onAgenda}
          >
            <CalendarIcon />
            {t('menu__option_agenda')}
          </button>
        </FeatureFlag>
        <button
          className="flex flex-row w-full rounded-lg justify-start gap-2 p-1 items-center text-negative hover:bg-gray-light text-xs"
          onClick={onLogout}
        >
          <DoorArrowRight />
          {t('menu__option_logout')}
        </button>
      </div>
    </Modal>
  );
};

export default LogoContainer;
