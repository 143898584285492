import { FC, FormEvent, PropsWithChildren, useEffect } from 'react';
import classnames from 'classnames';
import AlertIcon from '../../assets/icons/AlertIcon';
import Info from '../../assets/icons/Info';
import './index.css';

interface Props extends PropsWithChildren {
  onSubmit?: (event: FormEvent) => void;
  className?: string;
}

interface Nested {
  Group: FC<any>;
  Field: FC<any>;
  Date: FC<any>;
  Time: FC<any>;
}

const Form: FC<Props> & Nested = ({ onSubmit, children, className }) => {
  return (
    <form className={className} onSubmit={onSubmit}>
      {children}
    </form>
  );
};

const Field: FC<any> = ({
  type = 'text',
  disabled,
  options,
  name,
  autoFocus,
  autoComplete,
  placeholder,
  defaultValue,
  required,
  label,
  register,
  readOnly,
  isDirty,
  isLoading,
  errors,
  className,
  onBlur,
  info,
  onChange,
  maxLength,
  pattern,
  onDisabledClick,
}) => {
  useEffect(() => {
    info && console.log(info);
  }, [info]);

  return (
    <div
      className={classnames(
        'w-full relative mb-2 font-normal',
        { 'opacity-50': disabled },
        className ?? '',
      )}
    >
      <Label hidden={!isDirty && !defaultValue}>
        {`${label} ${required ? '*' : ''}`}
      </Label>
      {isLoading ? (
        <Skeleton />
      ) : (
        {
          select: (
            <Select
              options={options}
              name={name}
              defaultValue={defaultValue}
              register={register}
              readOnly={readOnly}
              disabled={disabled}
              isDirty={isDirty}
              errors={errors}
              onChange={onChange}
            />
          ),
          email: (
            <Text
              type="email"
              disabled={disabled}
              register={register}
              name={name}
              required={required}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              placeholder={placeholder}
              defaultValue={defaultValue}
              readOnly={readOnly}
              onBlur={onBlur}
              isDirty={isDirty}
              errors={errors}
              onChange={onChange}
              onDisabledClick={onDisabledClick}
            />
          ),
          password: (
            <Text
              type="password"
              disabled={disabled}
              register={register}
              required={required}
              name={name}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              placeholder={placeholder}
              defaultValue={defaultValue}
              readOnly={readOnly}
              onBlur={onBlur}
              isDirty={isDirty}
              errors={errors}
              onChange={onChange}
            />
          ),
          text: (
            <Text
              type="text"
              disabled={disabled}
              register={register}
              required={required}
              name={name}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              placeholder={placeholder}
              defaultValue={defaultValue}
              readOnly={readOnly}
              onBlur={onBlur}
              isDirty={isDirty}
              errors={errors}
              onChange={onChange}
              maxLength={maxLength}
              pattern={pattern}
              onDisabledClick={onDisabledClick}
            />
          ),
          tel: (
            <Tel
              type="tel"
              disabled={disabled}
              register={register}
              required={required}
              name={name}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              placeholder={placeholder}
              defaultValue={defaultValue}
              readOnly={readOnly}
              onBlur={onBlur}
              isDirty={isDirty}
              onChange={onChange}
              errors={errors}
            />
          ),
          textarea: (
            <TextArea
              register={register}
              disabled={disabled}
              name={name}
              required={required}
              autoFocus={autoFocus}
              autoComplete={autoComplete}
              placeholder={placeholder}
              defaultValue={defaultValue}
              readOnly={readOnly}
              onBlur={onBlur}
              isDirty={isDirty}
              errors={errors}
            />
          ),
        }[type as 'text' | 'select' | 'email' | 'textarea' | 'password']
      )}
      {!errors && info && (
        <Info
          data-tip={info}
          className="absolute w-5 h-5 cursor-pointer right-4 top-4 text-gray-medium"
        />
      )}
      {errors && <Error>{errors.message}</Error>}
    </div>
  );
};
const Select: FC<any> = ({
  options,
  name,
  defaultValue,
  register,
  readOnly,
  isDirty,
  isLoading,
  errors,
}) =>
  isLoading ? (
    <div className="flex items-center w-full px-4 pt-4 mb-6 border-none rounded-lg text-gray-medium min-h-input focus:ring-2  bg-bg focus:outline-none">
      Loading
    </div>
  ) : (
    <select
      name={name}
      disabled={readOnly}
      defaultValue={defaultValue}
      {...register}
      className={`${classnames({
        'focus:ring-2 focus:ring-offset-primary-dark': !errors && !readOnly,
        'text-gray-medium': readOnly,
        'border border-negative': !!errors,
        'pt-4': isDirty || defaultValue,
      })} w-full px-3 mb-2 sm:mb-6  bg-bg rounded-lg focus:outline-none`}
    >
      <option className="hidden" value="" />
      {options?.map((option: any) => (
        <option key={option.key ?? option} value={option.key ?? option}>
          {option.name ?? option}
        </option>
      ))}
    </select>
  );

const Text: FC<any> = ({
  type,
  register,
  required,
  name,
  autoFocus,
  autoComplete,
  placeholder,
  defaultValue,
  readOnly,
  isDirty,
  //value,
  onBlur,
  errors,
  onChange,
  maxLength,
  pattern,
  onDisabledClick,
}) => (
  <input
    tabIndex={readOnly ? -1 : 0}
    name={name}
    type={pattern ? pattern : type}
    readOnly={readOnly}
    defaultValue={defaultValue}
    autoFocus={autoFocus}
    placeholder={`${placeholder}${required ? '*' : ''}`}
    autoComplete={autoComplete}
    onBlur={onBlur}
    {...register(name, { required })}
    className={classnames(
      'w-full px-4 mb-2 sm:mb-6 bg-bg rounded-lg focus:outline-none font-lighter',
      {
        error: !!errors,
        'text-gray-medium opacity-70': readOnly,
        'border border-negative': !!errors,
        'pt-4': isDirty || defaultValue,
      },
    )}
    onChange={onChange}
    maxLength={maxLength}
    pattern={pattern}
    onClickCapture={readOnly && onDisabledClick}
  />
);

const Tel: FC<any> = ({
  register,
  required,
  name,
  autoFocus,
  autoComplete,
  placeholder,
  defaultValue,
  readOnly,
  isDirty,
  value,
  onBlur,
  errors,
  onChange,
}) => {
  return (
    <input
      tabIndex={readOnly ? -1 : 0}
      name={name}
      type="number"
      readOnly={readOnly}
      defaultValue={defaultValue}
      autoFocus={autoFocus}
      placeholder={`${placeholder}${required ? '*' : ''}`}
      autoComplete={autoComplete}
      onBlur={onBlur}
      {...register(name, {
        required: required,
        pattern: required
          ? {
              value: value,
              message: 'Invalid phone number',
            }
          : {},
      })}
      onChange={onChange}
      className={classnames(
        'w-full px-4 mb-2 sm:mb-6 bg-bg rounded-lg focus:outline-none font-lighter',
        {
          error: !!errors,
          'text-gray-medium opacity-70': readOnly,
          'border border-negative': !!errors,
          'pt-4': isDirty || defaultValue,
        },
      )}
      onKeyDown={(evt) => {
        ['e', 'E', '+', '-'].includes(evt.key) && evt.preventDefault();
        isDirty;
      }}
    />
  );
};

const TextArea: FC<any> = ({
  register,
  name,
  autoFocus,
  autoComplete,
  placeholder,
  defaultValue,
  readOnly,
  isDirty,
  onBlur,
  required,
  errors,
}) => (
  <textarea
    name={name}
    readOnly={readOnly}
    defaultValue={defaultValue}
    autoFocus={autoFocus}
    placeholder={`${placeholder}${required ? '*' : ''}`}
    autoComplete={autoComplete}
    onBlur={onBlur}
    ref={register}
    rows={5}
    className={classnames(
      'w-full px-4 pt-2 mb-2 sm:mb-6  bg-bg rounded-lg focus:outline-none',
      {
        'focus:outline-none focus-visible:ring-1 focus-visible:ring-primary':
          !errors,
        'text-gray-medium opacity-70': readOnly,
        'border border-negative': !!errors,
        'pt-6': isDirty || defaultValue,
      },
    )}
  />
);

const Error: FC<Props> = ({ children }) => (
  <>
    <small className="absolute bottom-0 right-0 text-negative">
      {children}
    </small>
    <AlertIcon className="absolute right-4 top-4" />
  </>
);

const Skeleton: FC = () => (
  <div className="w-full mb-2 rounded-lg h-14  bg-bg sm:mb-6 animate-pulse" />
);

export const Label: FC<{ hidden?: boolean; children: any }> = ({
  children,
  hidden,
}) => (
  <label
    className={classnames(
      'absolute left-4 top-2 text-sm z-10 text-primary tracking-wide font-lighter',
      {
        hidden,
      },
    )}
  >
    {children}
  </label>
);

const Group: FC<any> = ({ children }) => (
  <div className="flex w-full gap-4">{children}</div>
);

const Date: FC<any> = ({
  label,
  max,
  min,
  name,
  defaultValue,
  register,
  readOnly,
  errors,
  onChange,
  onDisabledClick,
}) => (
  <div
    className={classnames(
      'relative bg-bg font-normal rounded-lg mb-2 sm:mb-6 px-2',
      {
        'focus:ring-2 focus:ring-offset-primary-dark': !errors && !readOnly,
        'text-gray-medium opacity-70': readOnly,
        'border border-negative': !!errors,
      },
    )}
  >
    <Label>{label}</Label>
    <div className="focus:outline-none bg-bg rounded-lg">
      <input
        name={name}
        defaultValue={defaultValue}
        ref={register}
        type="date"
        max={max}
        min={min}
        className={classnames(
          'font-lighter min-w-full w-full pr-12 rounded-lg',
        )}
        onChange={onChange}
        readOnly={readOnly}
        onClickCapture={readOnly && onDisabledClick}
      />
    </div>
    {errors && <Error>{errors.message}</Error>}
  </div>
);

const Time: FC<any> = ({
  label,
  name,
  max,
  min,
  defaultValue,
  register,
  readOnly,
  errors,
}) => (
  <div className={classnames('w-full relative mb-2 font-normal')}>
    <Label>{label}</Label>
    <input
      name={name}
      defaultValue={defaultValue}
      max={max}
      min={min}
      ref={register}
      type="time"
      className={classnames(
        'w-full pt-4 pl-4 pr-10 mb-2 sm:mb-6  bg-bg rounded-lg focus:outline-none font-lighter',
        {
          'focus:ring-2 focus:ring-offset-primary-dark': !errors && !readOnly,
          'text-gray-medium opacity-70': readOnly,
          'border border-negative': !!errors,
        },
      )}
    />
    {errors && <Error>{errors.message}</Error>}
  </div>
);

Form.Date = Date;
Form.Time = Time;
Form.Group = Group;
Form.Field = Field;

export default Form;
