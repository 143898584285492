import { useState } from 'react';
import LogoContainer from '../../components/LogoContainer';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@tanstack/react-query';
import { Form } from './Form';
import rqOptions from '../../rq/options';

export type Phone = {
  prefix: string | any;
  number?: string;
  phone?: string;
  routes?: any;
};

const SelectCountry = () => {
  const [screen, setScreen] = useState<string>('routes');
  const { i18n } = useTranslation();
  const language = i18n.resolvedLanguage ?? 'es';

  const countryCodeFromIPQuery = useQuery(rqOptions.countries.getCodeFromIP);
  const countriesQuery = useQuery(rqOptions.countries.getAll(language));

  if (countriesQuery.isLoading || countryCodeFromIPQuery.isLoading) {
    return <></>;
  }

  const countryCode =
    countryCodeFromIPQuery.data?.country_code?.toLowerCase() ?? language;

  return (
    <div className="min-h-full h-full flex flex-col w-full">
      <LogoContainer setScreen={setScreen} screen={screen} />

      <Form countries={countriesQuery.data ?? []} countryCode={countryCode} />
    </div>
  );
};

export default SelectCountry;
