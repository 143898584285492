import { queryOptions } from '@tanstack/react-query';
import api from '../../api';
import countriesJson from '../../utils/data/countries.json';
import countriesList from 'i18n-iso-countries';

export type Country = { name: string; code: string; prefix: string };

export const getCodeFromIP = queryOptions({
  queryKey: ['ip-country-code'],
  queryFn: api.getCountryCodeFromIP,
});

export const getAll = (countryCode: string) =>
  queryOptions({
    queryKey: ['countries'],
    queryFn: api.getCountries,
    select: (countriesCode) => {
      const countries: Array<Country> = [];

      for (const country of countriesJson) {
        const lowercasedCode = country.code.toLowerCase();
        const countryName = countriesList.getName(lowercasedCode, countryCode);

        if (countriesCode.includes(lowercasedCode) && countryName) {
          countries.push({
            name: countryName,
            code: country.code,
            prefix: country.prefix,
          });
        }
      }

      return countries.sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });
    },
  });
