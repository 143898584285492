import { useForm } from 'react-hook-form';
import Button from '../../components/Button';
import { Phone } from '../../pages/Login';
import FormPhone from '../../components/Form/Phone';
import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import ReturnButton from './_components/ReturnButton';
import countriesJson from '../../utils/data/countries.json';

export interface PhoneFormProps {
  onSend: (phone: Phone) => void;
  onGoBack: () => void;
  onReturn: () => void;
}

const PhoneForm = ({ onSend, onReturn }: PhoneFormProps) => {
  const prefix = useMemo(() => {
    const countryCode = localStorage.getItem('country_code') ?? '';
    const country = countriesJson.find(
      (country) => country.code.toLowerCase() === countryCode.toLowerCase(),
    );
    return country?.prefix;
  }, []);

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: 'onSubmit',
    defaultValues: {
      prefix,
      phone: '',
    },
  });
  const { t } = useTranslation();
  const [formData, setFormData] = useState<{ phone?: string }>({});
  const [isValidForm, setIsValidForm] = useState(false);

  const watchFields = watch();

  const onChange = (value: any) => {
    const newData = {
      ...formData,
      phone: value?.target?.value,
    };
    setFormData(newData);
    setIsValidForm(isFormValid(newData));
  };

  const isFormValid = (data: any) => {
    return !!data.phone;
  };

  const onSubmit = (data: Phone) => {
    const phone = (formData.phone ?? data.phone)?.replace(/[^0-9]/g, '');
    onSend && onSend({ phone, prefix: data.prefix });
  };

  return (
    <form className="w-full" onSubmit={handleSubmit(onSubmit)}>
      <FormPhone
        register={register}
        control={control}
        isDirty={watchFields['phone']}
        errors={errors['phone']}
        onChange={onChange}
      />

      <Button className="ml-auto mt-8" disabled={!isValidForm} type="submit">
        {t('login__phone_register_submit_button')}
      </Button>
      <ReturnButton onClick={onReturn}>
        {t('login__channel_form_back_button')}
      </ReturnButton>
    </form>
  );
};

export default PhoneForm;
