import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import HeaderView from '../../components/HeaderView';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Button from '../../components/Button';
import { FC } from 'react';
import Select from 'react-select';
import { Country } from '../../rq/options/countries';

interface FormProps {
  countries: Array<Country>;
  countryCode?: string;
}

interface SelectCountryForm {
  country_code: string;
}

export const Form = ({ countries, countryCode }: FormProps) => {
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<SelectCountryForm>({
    defaultValues: { country_code: countryCode },
  });
  const navigate = useNavigate();

  const onSubmit = ({ country_code }: { country_code: string }) => {
    localStorage.setItem('country_code', country_code.toLowerCase());
    navigate('/');
  };

  return (
    <div className="h-full flex flex-col justify-center items-center p-6 ">
      <HeaderView
        title={t('country__header_title')}
        subtitle={t('country__header_subtitle')}
      />
      <form
        className="w-full flex flex-col my-8 max-w-md"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Dropdown
          errors={errors}
          control={control}
          name="country_code"
          options={countries.map(({ code, name }) => ({
            value: code.toLowerCase(),
            label: `${name}`,
          }))}
          placeholder={t('country__header_select_placeholder')}
          defaultValue={countryCode}
        />

        <Button className="ml-auto mt-8" type="submit" disabled={!isValid}>
          {t('country__header_submit_button')}
        </Button>
      </form>
    </div>
  );
};

const Dropdown: FC<any> = ({
  name,
  required,
  options,
  control,
  defaultValue,
  placeholder,
}) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required,
      }}
      render={({ field: { onChange, value, ref } }) => (
        <Select
          className={classnames('Dropdown w-full')}
          styles={{
            menu: (provided) => ({ ...provided }),
            singleValue: (base) => ({ ...base }),
          }}
          placeholder={placeholder}
          menuPlacement="bottom"
          isSearchable={true}
          classNamePrefix="ReactDropdown"
          options={options}
          ref={ref}
          value={options.find((o: any) => o.value === value)}
          onChange={(selectedOption: any) => onChange(selectedOption.value)}
          defaultValue={options.find((o: any) => o.value === defaultValue)}
        />
      )}
    />
  );
};
