import { useEffect, useState } from 'react';
import { AppLanguage, isSupportedLanguage } from '../i18n/locales';
import countriesList from 'i18n-iso-countries';
import { useTranslation } from 'react-i18next';

const lazyImportISOCountries = async (lang: AppLanguage) => {
  let locale;
  switch (lang) {
    case 'en':
      locale = import('i18n-iso-countries/langs/en.json');
      break;
    case 'es':
      locale = import('i18n-iso-countries/langs/es.json');
      break;
    case 'pt':
      locale = import('i18n-iso-countries/langs/pt.json');
      break;
  }

  countriesList.registerLocale(await locale);
};

export const useImportISOCountries = () => {
  const { i18n } = useTranslation();
  const language = i18n.resolvedLanguage ?? 'es';
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isSupportedLanguage(language)) {
      lazyImportISOCountries(language).finally(() => setIsLoading(false));
    }
  }, [language]);

  return { isLoading };
};
