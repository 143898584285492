import es from './es.json';
import en from './en.json';
import pt from './pt.json';

export const supportedLanguages = ['es', 'en', 'pt'] as const;

export type AppLanguage = (typeof supportedLanguages)[number];

export const isSupportedLanguage = (value: string): value is AppLanguage => {
  return supportedLanguages.includes(value as AppLanguage);
};

export const locales = {
  es: {
    translation: es,
  },
  en: {
    translation: en,
  },
  pt: {
    translation: pt,
  },
};
